export default {
    props: {
        iconClass: {
            type: String,
            default: ''
        },
        mobile: {
            type: Boolean,
            default: false
        }
    },
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};
function data(){
    return {
        errorMessages: [],
    };
}
function getComputed(){
    return {
        dynamicClasses(){
            const dynamicClasses = [this.iconClass];
            if(this.isUpdatingLinkedAccounts){
                dynamicClasses.push('fa-spin disabled');
            }
            return dynamicClasses;
        },
        isUpdatingLinkedAccounts(){
            return this.$store.state.authorized.isFetchingDataFromPlaid;
        },
        hasBankAccountsToRefresh(){
            return this.$store.getters['authorized/bankAccounts/bankAccountsLinked'].length > 0;
        }
    };
}
function created(){}
function getMethods(){
    return {
        displayErrorMessage,
        updateLinkedAccountBalances
    };
    function displayErrorMessage(error){
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            this.$bvToast.toast(errorMessage, {
                title: 'Refresh Error',
                variant: 'danger',
                solid: true,
            });
        }
    }
    function updateLinkedAccountBalances(){
        const vm = this;
        if(!vm.isUpdatingLinkedAccounts){
            // fire refresh linked accounts
            // use the response to update the linked accounts
            vm.$store.dispatch('authorized/FETCHING_DATA_FROM_PLAID', 'refresh');
            setTimeout(() => {
                vm.$store.dispatch('authorized/REFRESH_LINKED_ACCOUNTS').then(handleRefreshSuccess).catch(vm.displayErrorMessage).finally(resetLoadingState);
            }, 2000);
        }

        function handleRefreshSuccess(){
            vm.$bvToast.toast('We successfully requested a refresh on your accounts. Please check back in a few minutes.', {
                title: 'Success',
                variant: 'success',
                solid: true,
            });
            setTimeout(() => {
                vm.$store.dispatch('authorized/TRANSACTION_REFRESH_ERRORS').then(handleRefreshErrors);
            }, 10000);
            function handleRefreshErrors(response){
                if(response.data.message === 'TRANSACTION_REFRESH_ERRORS'){
                    response.data.errorResults.map((institution) => {
                        const errorMessage = institution.name + ' does not allow transactions to be retrieved manually. ' +
                            'You must wait until transactions are automatically brought in.';
                        vm.$bvToast.toast(errorMessage, {
                            title: 'Unsupported Institution',
                            variant: 'danger',
                            solid: true,
                        });
                    });
                }
            }
        }

        function resetLoadingState(){
            vm.$store.dispatch('authorized/FETCHING_DATA_FROM_PLAID', false);
        }
    }
}
